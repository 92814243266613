import styled from "@emotion/styled";
import React, { useContext, useMemo } from "react";
import { DoctorProfilePage as PageDefinition, GetPageContext } from "../../../pages";
import { Doctor, Location } from "../../../types/directus/Doctor";
import { MOBILE_LG, MOBILE_MD } from "../../../utils/breakpoints";
import {
  getDegrees,
  getLchfTrainingPrograms,
  getPrimaryLocation,
  getSpecialties,
  getTitle,
} from "../../../utils/doctor";
import { toCMSAssetUrl } from "../../../utils/utils";
import { pageContext } from "../../../components/PageProvider/PageProvider";
import PageSection from "../../../components/PageSection/PageSection";
import { Quote } from "../Quote/Quote";
import { SEOReactHelmet } from "../../../components/SEO/SEOReactHelmet";

const MAP_WIDTH = 400;
const MAP_HEIGHT = 300;

const Header = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 32px;
  line-height: normal;

  @media (min-width: ${MOBILE_MD}) {
    flex-direction: row;
    align-items: flex-start;

    & > * {
      margin-right: 32px;
    }
    & > *:last-child {
      margin-right: 0;
    }
  }
`;

const Avatar = ({ id, fullName }: { id: string; fullName: string }) => {
  return (
    <div className="w-60 h-60 mr-4 rounded-full shrink-0 overflow-hidden flex justify-center items-center">
      <img src={toCMSAssetUrl(id, 240)} alt={fullName} />
    </div>
  );
};

const Main = styled.main`
  display: flex;
  flex-direction: column;

  @media (min-width: ${MOBILE_MD}) {
    flex-direction: row;
  }
`;

const SecondarySection = styled.section`
  flex-shrink: 0;

  @media (min-width: ${MOBILE_MD}) {
    flex-basis: 300px;
    flex-shrink: 1;
  }

  @media (min-width: ${MOBILE_LG}) {
    flex-basis: 400px;
  }
`;

const PrimarySection = styled.section`
  flex-grow: 1;
`;

const StaticMapImage = styled.img`
  width: 100%;
  max-width: ${MAP_WIDTH}px;
  height: 300px;
`;

const Heading = styled.h2`
  font-size: 1.2em;
  color: gray;
`;

const MAP_SCALE = 2;
const MAP_ZOOM = 12;

function getStaticMapURL(location: [number, number]): string {
  return `https://maps.googleapis.com/maps/api/staticmap?key=${process.env.GATSBY_GOOGLE_MAPS_API_KEY}&size=${MAP_WIDTH}x${MAP_HEIGHT}&scale=${MAP_SCALE}&zoom=${MAP_ZOOM}&markers=${location[1]},${location[0]}`;
}

function getDirectionsURL(location: [number, number]): string {
  return `https://www.google.com/maps/dir/?api=1&destination=${location[1]},${location[0]}`;
}

interface LocationSectionProps {
  location: Location;
  locationVisibility: boolean;
}

const LocationSection: React.FunctionComponent<LocationSectionProps> = ({ location, locationVisibility }) => {
  const { phoneNumber, emailAddress, website } = location;
  const hasContactInfo = Boolean(phoneNumber) || Boolean(emailAddress) || Boolean(website);
  const isLocationVisible = Boolean(locationVisibility);

  return (
    <>
      {location?.location?.coordinates && isLocationVisible && (
        <a href={getDirectionsURL(location.location.coordinates)} target="_blank" rel="noopener noreferrer">
          <StaticMapImage src={getStaticMapURL(location.location.coordinates)} />
        </a>
      )}
      {location?.location?.coordinates && isLocationVisible && (
        <p>
          <a href={getDirectionsURL(location.location.coordinates)} target="_blank" rel="noopener noreferrer">
            {Boolean(location.name) && (
              <>
                {location.name}
                <br />
              </>
            )}
            {location.address}
          </a>
        </p>
      )}
      {hasContactInfo && (
        <>
          <ul className="p-0">
            {Boolean(phoneNumber) && (
              <li>
                Phone number: <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
              </li>
            )}
            {Boolean(emailAddress) && (
              <li>
                E-mail address: <a href={`mailto:${emailAddress}`}>{emailAddress}</a>
              </li>
            )}
            {Boolean(website) && (
              <li>
                Website:{" "}
                <a href={website} target="_blank" rel="noopener noreferrer">
                  {website}
                </a>
              </li>
            )}
          </ul>
        </>
      )}
    </>
  );
};

function getPageTitle(doctor: Doctor): string {
  const title = getTitle(doctor);
  const primaryLocation = getPrimaryLocation(doctor);
  const location = primaryLocation?.name ? ` at ${primaryLocation.name}` : "";

  return `${title}${location} – Diet Doctor`;
}

type DoctorProfilePageContext = GetPageContext<typeof PageDefinition>;

const DoctorProfilePage: React.FunctionComponent = () => {
  const {
    extraContext: { doctor },
  } = useContext<DoctorProfilePageContext>(pageContext);

  const courses = useMemo(() => getLchfTrainingPrograms(doctor), [doctor]);

  return (
    <>
      <SEOReactHelmet title={getPageTitle(doctor)} />
      <PageSection>
        <Header>
          {doctor.profilePhoto && <Avatar id={doctor.profilePhoto.id} fullName={doctor.fullName} />}
          <div>
            <h1>{getTitle(doctor)}</h1>
            <p>{doctor.profession?.name}</p>
            {doctor.testimonial && <Quote>{doctor.testimonial}</Quote>}
          </div>
        </Header>

        <Main>
          <SecondarySection>
            <Heading>Degrees</Heading>
            <ul className="p-0">
              {getDegrees(doctor).map((degree) => (
                <li key={degree}>{degree}</li>
              ))}
            </ul>

            <Heading>Specialties</Heading>
            <ul className="p-0">
              {getSpecialties(doctor).map((specialty) => (
                <li key={specialty}>{specialty}</li>
              ))}
            </ul>
            {courses.length > 0 && (
              <>
                <Heading>Courses</Heading>
                <ul className="p-0">
                  {courses.map((course) => (
                    <li key={course}>{course}</li>
                  ))}
                </ul>
              </>
            )}
          </SecondarySection>

          <PrimarySection>
            {doctor.locations?.length > 0 && (
              <>
                <Heading>Contact information</Heading>
                {doctor.locations.map((location) => (
                  <LocationSection
                    key={location.name}
                    location={location}
                    locationVisibility={doctor.locationVisibility}
                  />
                ))}
              </>
            )}

            {doctor.websites?.length > 0 && (
              <>
                <Heading>More information</Heading>
                {doctor.websites.map(({ id, website }) => (
                  <p key={id}>
                    <a href={website} target="_blank" rel="noopener noreferrer">
                      {website}
                    </a>
                  </p>
                ))}
              </>
            )}
          </PrimarySection>
        </Main>
      </PageSection>
    </>
  );
};

// ts-prune-ignore-next
export default DoctorProfilePage;
